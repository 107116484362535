angular.module("app")
    .controller("AccountController", function($rootScope, $scope, _v, _view, _appStorage, _mapService) {
        $scope.items = [];

        $rootScope.$on("_view:urlReady", getData);

        function getData() {
            $scope.items = _view.getAccounts();

            // Select current account or first available account
            $scope.selected = $scope.items.find(account => account.id == _view.getCurrentAccount()) || $scope.items[0];
        }

        $scope.switch = function(e) {
          _mapService.disableUrlChangeOnMapMove();
          _appStorage.set('account', $scope.selected.id);

          var emptyBean = {};
          _.each(_v.$v, function(value, key, list){
              emptyBean[key] = undefined;
          });

          emptyBean.account = $scope.selected.id;

          $rootScope.$broadcast('_view:closeAllScreens');
          _v.change({set: emptyBean, viewCropMonitor: undefined, addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined});
          $scope.status.isOpen2 = false;
        };
});